@font-face {
  font-family: "FreightDispProBook";
  src: url("./fonts/FreightDispProBook-regular.woff2") format("woff2"),
    url("./fonts/FreightDispProBook-regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Whyte";
  src: local("Whyte-Regular"),
    url("./fonts/ABCWhyte-Regular.woff2") format("woff2"),
    url("./fonts/ABCWhyte-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: "Whyte-Bold";
  src: local("Whyte-Bold"), url("./fonts/ABCWhyte-Bold.woff2") format("woff2"),
    url("./fonts/ABCWhyte-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-display: swap;
  font-family: "Whyte-Italic";
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src: local("Whyte-Italic"),
    url("./fonts/Whyte-Regular-Italic.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Whyte-Medium";
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src: local("Whyte-Medium"), url("./fonts/Whyte-Medium.woff") format("woff");
}

@font-face {
  font-display: "swap";
  font-family: "Georgia";
  src: url("./fonts/Georgia.woff") format("woff");
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Public Sans";
  src: local("PublicSans-Regular"),
    url("./fonts/PublicSans-Regular.woff2") format("woff2"),
    url("./fonts/PublicSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Flama", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(241, 244, 249, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #717171;
}
a:hover {
  color: #2d2d2d;
}

h3,
h4,
h5,
h6 {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 12px;
}

/* overide tooltip styling */
.MuiTooltip-tooltip {
  background-color: white !important;
  padding: 0 !important;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(45, 45, 45, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(45, 45, 45, 0.4);
}

.formRowSpacing {
  margin-top: 24px;
}

@media only screen and (max-width: 960px) {
  .formRowSpacing {
    margin-top: 20px;
  }
}
