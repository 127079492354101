.formControl {
  margin: 10px 0 !important;
}

.formControl .MuiFormHelperText-root {
  color: #fa755a;
}

.stripeElementContainer {
  position: relative;
  height: 48px;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #fafafa;
  padding: 10px 0;
}

.stripeElementContainer:hover {
  background-color: rgba(0, 0, 0, 0.09);
  transition: 0.2s;
}

.stripeElementContainer::before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}

.stripeElementContainer::after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #2d2d2d;
  pointer-events: none;
}

.stripeElementContainer.focused::after,
.stripeElementContainer.error::after {
  transform: scaleX(1);
}

.stripeElementContainer.error::after {
  border-bottom-color: #f44336;
}

.stripeElement {
  border: none;
  width: 100%;
  background-color: transparent;
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
}

.stripeErrorText {
  color: #f44c49 !important;
  font-size: 14px !important;
  font-weight: 700;
}
